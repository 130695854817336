import { useEffect, useState } from "react"
import { CLAY_STYLE, GLASS_STYLE, PREFLY_PRIMARY_COLOR, PREFLY_SECONDARY_COLOR } from "../Colors"
import { Autocomplete, Button, Grid, TextField, Card } from "@mui/material";
import blobSvg from './blob.svg';
import { listUrls, renderPage } from "../api/PreflyService";

export const Dashboard = () => {

    const [pages, setPages] = useState([
        // {
        //     thumbnailUrl: 'https://pages.prefly.io/tenitx.com/screenshot.jpeg',
        //     pageUrl: 'tenitx.com',
        //     preRenderedUrl: 'https://pages.prefly.io/tenitx.com'
        // },
        // {
        //     thumbnailUrl: 'https://pages.prefly.io/tenitx.com/screenshot.jpeg',
        //     pageUrl: 'tenitx.com',
        //     preRenderedUrl: 'https://pages.prefly.io/tenitx.com'
        // },
        // {
        //     thumbnailUrl: 'https://pages.prefly.io/tenitx.com/screenshot.jpeg',
        //     pageUrl: 'tenitx.com',
        //     preRenderedUrl: 'https://pages.prefly.io/tenitx.com'
        // },
        // {
        //     thumbnailUrl: 'https://pages.prefly.io/getfynd.app/screenshot.jpeg',
        //     pageUrl: 'getfynd.app',
        //     preRenderedUrl: 'https://pages.prefly.io/getfynd.app'
        // },
        // {
        //     thumbnailUrl: 'https://pages.prefly.io/getfynd.app/business-owner/screenshot.jpeg',
        //     pageUrl: 'getfynd.app/business-owner',
        //     preRenderedUrl: 'https://pages.prefly.io/getfynd.app/business-owner'
        // },
        // {
        //     thumbnailUrl: 'https://pages.prefly.io/tenitx.com/feature-flags/screenshot.jpeg',
        //     pageUrl: 'tenitx.com/feature-flags',
        //     preRenderedUrl: 'https://pages.prefly.io/tenitx.com/feature-flags'
        // }
    ]);

    const [url, setUrl] = useState("");


    useEffect(() => {
        listUrls(1001, (resp) => {
            setPages(resp.data.map(p => (
                {
                    thumbnailUrl: `https://pages.prefly.io/${p.url}/screenshot.jpeg`,
                    pageUrl: p.url,
                    preRenderedUrl: `https://pages.prefly.io/${p.url}`
                }
            )))
        })
    }, []);


    return (
        <div style={Styles.main}>
           <input value={url} onChange={(e) => setUrl(e.target.value)}></input>
                <Button
                style={{marginBottom: 200, color: "whitesmoke"}}
                onClick={() => {
                    renderPage(url, () => {
                        let u = removeTrailingSlash(url);
                        setPages([...pages, {
                            thumbnailUrl: `https://pages.prefly.io/${u}/screenshot.jpeg`,
                            pageUrl: u,
                            preRenderedUrl: `https://pages.prefly.io/${u}`
                        }])
                    })
                }}>Prerender</Button>

            <Grid container columns={{xs: 1, sm: 2, md: 2}} rowSpacing={{ xs: 1, sm: 2, md: 3 }} columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                style={{}}
            >
                {pages.map(p => <PageCard page={p} />)}
            </Grid>
        </div>
    )
}

const PageCard = ({ page }) => {
    
    return  (
        <a style={{textDecoration: "none"}} href={`/page?url=${page.pageUrl}`} >
        <Card
            style={{...GLASS_STYLE, padding: 10, margin: 10}}
        >
            <img src={page.thumbnailUrl} alt={`${page.pageUrl} screenshot`} width={350} style={{...GLASS_STYLE, borderRadius: 25}} />
            <h2 style={{color: "white"}}>{page.pageUrl}</h2>
            <Button style={{...GLASS_STYLE, padding: 10, margin: 10}} >Load Prerendered Page</Button>
        </Card>
        </a>
    );
}

const Styles = {
    main: {
        // background: PREFLY_PRIMARY_COLOR,
        // backgroundImage: "./blob-1-opacity-50.gif",
        height: "100%"
    }
}

function removeTrailingSlash(str) {
    str = str.replace("https://", "").replace("http://", "");
    str = str.endsWith('/') ? str.slice(0, -1) : str;
    return str;
}