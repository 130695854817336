export const PREFLY_PRIMARY_COLOR = "#2196f3";
export const PREFLY_SECONDARY_COLOR = "#fce8d9";

export const GLASS_STYLE = {
    background: 'rgba( 255, 255, 255, 0.4 )',
    boxShadow: '0 8px 32px 0 rgba( 31, 38, 135, 0.37 )',
    backdropFilter: 'blur( 7px )',
    webkitBackdropFilter: 'blur( 7px )',
    borderRadius: '15px',
    border: '1px solid rgba( 255, 255, 255, 0.18 )'
}

export const CLAY_STYLE = {
    backdropFilter: 'blur(5px)',
    backgroundColor: 'rgba(255, 255, 255, 0.2)',
    borderRadius: '23px',
    boxShadow: '35px 35px 68px 0px rgba(33, 150, 243, 0.5), inset -8px -8px 16px 0px rgba(33, 150, 243, 0.6), inset 0px 11px 28px 0px rgb(255, 255, 255)'
}