import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Abc, AccountBalance, AccountBox, AltRoute, Build, Lock, Logout, Person, Support } from '@mui/icons-material';
import { useNavigate } from 'react-router';
// import Logo from '../full-logo.png';
// import { TENIT_PRIMARY_COLOR, TENIT_SECONDARY_COLOR } from '../Colors';
import { Chip, MenuItem, Select, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { GLASS_STYLE, PREFLY_PRIMARY_COLOR, PREFLY_SECONDARY_COLOR } from '../Colors';
// import { clearAllUserInfo, getAccountsUserHasAccessTo, getSelectedAccount, setUsersSelectedAccount } from '../api/User';
// import { featuresAppRoot, featuresDashboard, isSmallWindow } from '../FeaturesConstants';

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

const PrimaryNavigationItems = [
    {
        name: "Dashboard",
        icon: <AltRoute />,
        uri: '/dashboard'
    },
    // {
    //   name: "Values",
    //   icon: <Abc />,
    //   uri: `${featuresAppRoot}/values`,
    //   //"#4580ed"
    //   tag: <b><Chip variant='filled' sx={{backgroundColor: TENIT_SECONDARY_COLOR, color: "white"}} label="Beta"/></b>
    // },
    // {
    //   name: "User Settings",
    //   icon: <Person />,
    //   uri: `${featuresAppRoot}/values`,
    //   //"#4580ed"
    //   tag: <b><Chip variant='filled' sx={{backgroundColor: TENIT_SECONDARY_COLOR, color: "white"}} label="Beta"/></b>
    // },
    // {
    //     name: "API Tokens",
    //     icon: <Lock />,
    //     uri: `${featuresAppRoot}/api-tokens`
    // },
  //   {
  //     name: "Usage Guide",
  //     icon: <Build />,
  //     uri: `${featuresAppRoot}/usage-guide`
  // }
];

const SecondaryNavigationItems = [
    {
        name: "Team",
        icon: <AccountBox />,
        uri: `/account`
    },
    {
      name: "Support",
      icon: <Support />,
      uri: `/support`
  },
    {
        name: "Account Plan",
        icon: <AccountBalance />,
        uri: `/billing`
    },
    {
      name: "Sign out",
      icon: <Logout />,
      action: () => {
        console.log("Logging out...")
        //clearAllUserInfo();
      },
      uri: `/login`
  }
];

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  }
  
  export function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = React.useState(getWindowDimensions());
  
    React.useEffect(() => {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }
  
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);
  
    return windowDimensions;
  }


export default function SidebarNavigation(props) {
    const theme = useTheme();
    const { height, width } = useWindowDimensions();
    const [open, setOpen] = React.useState(width > 1000);

    const handleDrawerOpen = () => {
      setOpen(true);
    };
  
    const handleDrawerClose = () => {
      setOpen(false);
    };

    const nav = useNavigate();

    const [accounts, setAccounts] = useState([]);
    const [selectedAccount, setSelectedAccount] = useState();//getSelectedAccount());
    
    
    useEffect(() => {
        // getListOfAccountsUserHasAccessTo((a) => {
        //     setAccounts(a);   
        // });
        //[{"id": 1, "name": "Tenit X"}, {"id": 1001, "name": "Fynd"}]
      //  setAccounts(getAccountsUserHasAccessTo() || []);
    }, []);

    useEffect(() => {
      if (accounts.length > 0) {
        const acc = (accounts).find(a => a.id === parseInt(selectedAccount));
        document.title = `Tenit X - ${acc.name}`
      }
    }, [accounts, selectedAccount]);

    const updateSelectedAccount = (a) => {
       // setUsersSelectedAccount(a);
        setSelectedAccount(a);
        window.location.reload(false);
    }

    return (
      <Box sx={{ display: 'flex' }}>
        <AppBar position="fixed" open={open} sx={GLASS_STYLE}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{
                marginRight: 5,
                ...(open && { display: 'none' }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" noWrap component="div">
              Features
            </Typography>
            <Select style={{marginLeft: 35, color: "white", borderColor: "white"}} sx={{color: "white"}} value={selectedAccount} label="Account" autoWidth>
                {accounts.map(account => <MenuItem value={account.id} onClick={() => updateSelectedAccount(account.id)}>{account.name}</MenuItem>)}
            </Select>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <DrawerHeader>
            <a href="https://prefly.io"><img src={""} alt="Prefly Logo. The Word Prefly spelled out in white letters." width={200} style={{marginTop: -75, marginBottom: -75}} /></a>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
            </IconButton>
          </DrawerHeader>
          <Divider />
          <List>
            {PrimaryNavigationItems.map((item) => (
              <ListItem key={item.name} disablePadding sx={{ display: 'block', "&:hover": {
                color: PREFLY_SECONDARY_COLOR
              } }}>
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? 'initial' : 'center',
                    px: 2.5,
                    "&:hover": {
                      color: PREFLY_SECONDARY_COLOR
                    }
                  }}
                  
                  onClick={() => nav(item.uri)}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : 'auto',
                      justifyContent: 'center',
                    }}
                  >
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText primary={item.name} sx={{ opacity: open ? 1 : 0 }} />
                  {open && item.tag}
                </ListItemButton>
              </ListItem>
            ))}
          </List>
          <Divider />
          <List>
            {SecondaryNavigationItems.map((item) => (
              <ListItem key={item.name} disablePadding sx={{ display: 'block' }}>
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? 'initial' : 'center',
                    px: 2.5,
                    "&:hover": {
                      color: PREFLY_SECONDARY_COLOR
                    }
                  }}
                  onClick={() => {
                    if (item.action) {
                      item.action();
                    }
                    if (item.uri) {
                      nav(item.uri)
                    }
                  }
                }
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : 'auto',
                      justifyContent: 'center',
                    }}
                  >
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText primary={item.name} sx={{ opacity: open ? 1 : 0 }} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Drawer>
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          <DrawerHeader />
          {props.children}
        </Box>
      </Box>
    );
}