import { useEffect, useState } from "react"
import { CLAY_STYLE, GLASS_STYLE, PREFLY_PRIMARY_COLOR, PREFLY_SECONDARY_COLOR } from "../Colors"
import { Autocomplete, Button, Grid, TextField, Card, CircularProgress } from "@mui/material";
import blobSvg from './blob.svg';
import { lighthouseCompare, listUrls, renderPage } from "../api/PreflyService";
import { useParams } from "react-router";
import { useQueryParams } from "../QueryUtils";
import { CircularProgressWithLabel } from "../components/CircularProgressWithLabel";


export const PageDashboard = () => {

    let query = useQueryParams();
    let pageUrl = query.get("url");

    const [pages, setPages] = useState(
        {
            thumbnailUrl: `https://pages.prefly.io/${pageUrl}`,
            pageUrl: pageUrl,
            preRenderedUrl: `https://pages.prefly.io/${pageUrl}`
        });

    const [lighthouseResult, setLighthouseResult] = useState();

    const [url, setUrl] = useState("");


    useEffect(() => {
        lighthouseCompare(1001, pageUrl, (resp) => {
            setLighthouseResult(resp.data);
        });
    }, []);


    return (
        <div style={Styles.main}>
            <Card
            style={{...GLASS_STYLE, padding: 10, margin: 10, marginBottom: 20}}
            >
                <img src={`https://pages.prefly.io/${pageUrl}/screenshot.jpeg`} alt={`${pageUrl} screenshot`} width={500} style={{...GLASS_STYLE, borderRadius: 25}} />
                <h2 style={{color: "white"}}>{pageUrl}</h2>
            {!lighthouseResult && <div>
                    <CircularProgress sx={{color: PREFLY_PRIMARY_COLOR}} />
                    <h4>Loading Lighthouse scores...</h4>
                </div>}
            {lighthouseResult && <Grid container columns={{xs: 1, sm: 2, md: 2}} rowSpacing={{ xs: 1, sm: 2, md: 3 }} columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                style={{margin: "auto"}}
            >
                <div>
                    <h2>Original</h2>
                    <LighthouseResultCard result={lighthouseResult.original.lighthouseResult.categories.performance}/>
                    <LighthouseResultCard result={lighthouseResult.original.lighthouseResult.categories.seo}/>
                </div>
                <div>
                    <h2>Prefly Rendered</h2>
                    <LighthouseResultCard result={lighthouseResult.prefly.lighthouseResult.categories.performance}/>
                    <LighthouseResultCard result={lighthouseResult.prefly.lighthouseResult.categories.seo}/>
                </div>
            </Grid>}
            </Card>
        </div>
    )
}

const LighthouseResultCard = ({ result }) => {
    
    return  (
        <Card
            style={{...GLASS_STYLE, padding: 10, margin: 10}}
        >
            <h1>{result.title}</h1>
            <CircularProgressWithLabel value={result.score*100}/>
        </Card>
        
    );
}

const Styles = {
    main: {
        // background: PREFLY_PRIMARY_COLOR,
        // backgroundImage: "./blob-1-opacity-50.gif",
        height: "100vh"
    }
}

function removeTrailingSlash(str) {
    str = str.replace("https://", "").replace("http://", "");
    str = str.endsWith('/') ? str.slice(0, -1) : str;
    return str;
}