import axios from "axios";
// import { API_HOST } from "./API";
import { getBaseToken, getSecureToken } from "./User";

const API_HOST = "http://localhost:8080"

const USER_AGENT = "prefly-crawler-v1.0"

export const renderPage = async (url, onSuccess, onFailure) => {
    try {
     await (axios.post(`${API_HOST}/prefly/v1/url/ingest?accountId=1001&url=${url}`,
     {},
          {
            headers: {
              tbtc: getBaseToken(),
              tstc: getSecureToken()
            }
          }
        ).then(onSuccess)
        .catch(onFailure)
      );
     } catch(e) {
       console.error(e);
       onFailure(e);
     }
}

export const listUrls = async (accountId, onSuccess, onFailure) => {
  try {
   await (axios.get(`${API_HOST}/prefly/v1/url/list?accountId=${accountId}`,
        {
          headers: {
            tbtc: getBaseToken(),
            tstc: getSecureToken()
          }
        }
      ).then(onSuccess)
      .catch(onFailure)
    );
   } catch(e) {
     console.error(e);
     onFailure(e);
   }
}

export const lighthouseCompare = async (accountId, url, onSuccess, onFailure) => {
  try {
   await (axios.get(`${API_HOST}/prefly/v1/lighthouse/compare?accountId=${accountId}&url=${url}`,
        {
          headers: {
            tbtc: getBaseToken(),
            tstc: getSecureToken()
          }
        }
      ).then(onSuccess)
      .catch(onFailure)
    );
   } catch(e) {
     console.error(e);
     onFailure(e);
   }
}
