import './App.css';
import { Suspense, lazy } from "react";
import { BrowserRouter as Router,  Routes, Route } from "react-router-dom";
import { Dashboard } from './pages/Dashboard';
import SidebarNavigation from './components/SidebarNavigation'
import { PageDashboard } from './pages/PageDashboard';


function App() {
  return (
    <div className="App">
        <Suspense fallback={<div>Loading...</div>}>
          <Router>
        <Routes>
          <Route path={"dashboard"} element={<SidebarNavigation><Dashboard /></SidebarNavigation>} />
          <Route path={"page"} element={<SidebarNavigation><PageDashboard /></SidebarNavigation>} />
        </Routes>
        </Router>
      </Suspense>
    </div>
  );
}

export default App;
